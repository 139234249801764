import Swiper from '@iris.interactive/handcook/public/scripts/libraries/swiper-11'

export default class BlockCustomerFindOutMore {
    constructor() {
        if ($('[data-swiper="customer-find-out-more-slider"]').length > 0) {
            BlockCustomerFindOutMore.buildSlider()
        }
    }

    static async buildSlider() {
        const swiperKey = 'customer-find-out-more-slider'
        const slideItem = `[data-swiper="${swiperKey}"] > .block__customer__find-out-more__slide`

        const options = {
            mousewheel: {
                forceToAxis: true,
            },
            slidesPerView: 1,
            spaceBetween: 16,
            speed: 1000,
            navigation: false,
            keyboard: true,
            loop: false,
            freeMode: {
                enabled: true,
            },
            freeModeFluid: true,
            passiveListeners: false,
            watchSlidesVisibility: true,
            breakpoints: {
                650: {
                    slidesPerView: 2,
                },
                1024: {
                    slidesPerView: 3,
                },
            },
        }

        $(slideItem).wrapAll('<div class="swiper-wrapper"></div>').addClass('swiper-slide')
        const mySwiper = await Swiper.create(`[data-swiper="${swiperKey}"]`, options)
    }
}

new BlockCustomerFindOutMore()
